/* Adapted from https://www.youtube.com/watch?v=-VOUK-xFAyk */

$color1: #292929;
$color2: #454545;
$color3: #9A9A9A;

// From houdini spec
@property --gradient-angle {
    syntax: "<angle>";
    initial-value: -15deg;
    inherits: false;
}

@keyframes rotation {
    0% {
        --gradient-angle: -15deg;
    }

    100% {
        --gradient-angle: 345deg;
    }
}

.work-pill {
    color: var(--color-text-primary);
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    margin: 0 8px 0 5px;
    display: inline-block;
}

.work-pill::before,
.work-pill::after {
    content: "";
    position: absolute;
    height: 24px;
    border-radius: inherit;
    background: conic-gradient(from var(--gradient-angle),
            $color1,
            $color2,
            $color3,
            $color2,
            $color1);
    inset: 2px -5px 0 -1px;
    opacity: .4;
    transition: opacity .5s ease;
    animation: rotation 15s linear infinite;
    
    @media (max-width: 820px) {    
        inset: 2px -5px 0 -1px;
        height: 30px;
        font-size: 19px;
    }
}

.work-pill::before {
    filter: blur(4px); // If I could just disable this rule for Safari, I'd be happy
    transform: translate3d(0, 0, 0); // This solves hover color flash
}

.safari-desktop.work-pill::before {
    filter: none; /* Reset or override styles for Safari on desktop */
    transform: translate3d(0, 0, 0); // This solves hover color flash
}

.work-pill:hover::after, .work-pill:hover::before {
 opacity: .8;   
}

.inset-span {
    overflow: visible;
}

.inset-span::after {
    content: "";
    background: #1515156b;
    position: absolute;
    height: 22px;
    top: 3px;
    left: 0px;
    right: -4px;
    border-radius: 7px;
    z-index: 1;
    backdrop-filter: blur(10px);

    @media (max-width: 820px) {
        top: 3px;
        height: 28px;
    }
}

.work-pill-text {
    position: relative;
    z-index: 2;
    margin-right: 1px;
    font-size: 15px; // This is causing the line height distortion
    padding-left: 6px;
    font-family: "MonumentGroteskMedium";

    @media (max-width: 820px) {
        font-size: 19px;
    }
}

.work-pill-icon {
    position: relative;
    z-index: 2;
    transform: translateY(0.5px);
    margin-right: 1px;

    @media (max-width: 820px) {
        transform: translateY(-0.5px);
    }
}