#spline-player-layout-container {
    position: fixed;
    top: 0;
    right: 0;
    padding: var(--main-padding) var(--main-padding) var(--main-padding) 3%;
    height: 100%;
    width: 50%;
    z-index: 3;
    text-align: center;

    @media (max-width: 820px) {
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: none;
        pointer-events: auto;
    }

}

.spline-player-layout-container-visible {
    display: block !important; 
    pointer-events: auto;
}

.spline-player-clipping-container {
    height: 96%;
    width: 100%;
    border-radius: 80px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.06);

    @media (max-width: 820px) {
        height: 100%;
        border: none;
        border-radius: 0;
        pointer-events: none; // disables touch on phone which switches states
    }
}

.spline-player-label-layout-container {
    height: 4%;
    display: flex;
    align-items: end;
    justify-content: center;

    @media (max-width: 820px) {
        height: 10%;
        position: absolute;
        bottom: 0;
        align-items: center;
        width: 100%;
    }
}

.spline-player-label {

    text-transform: uppercase;
    font-family: 'ReplicaMonoLLWeb-Regular';
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    // transform: translateY(-8px);
    

    div {
        height: 4px;
        width: 4px;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        border-radius: 2px;
    }

    @media (max-width: 820px) {
        // transform: translateY(-76px);
    }
}

#icon-close-spline-viewer {
    display: none;
    top: 32px;
    right: 32px;
    
    @media (max-width: 820px) {
        display:block;
        
    }
}