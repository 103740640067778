#modal {
    margin-top: 200px;
    opacity: 0;
    width: 400px;
    z-index: 4;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #101010;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.50);
    padding: 48px;

    @media (max-width: 820px) {
        padding: 32px;
    }
}

#close-modal-button {
    float: right;
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 32px;
    right: 32px;
}

.modal-header {
    font-size: 28px;
    font-family: var(--headline-font);
    margin-bottom: 10px;
    color: var(--color-text-primary);
}

.modal-paragraph {
    font-size: 14px;
    color: var(--color-text-secondary);
    margin-bottom: 12px;
    line-height: 160%;
}

.modal-updated {
    font-family: "MonumentGrotesk-Italic";
    margin-bottom: 0;
}

.modal-link {
    color: white;
    font-family: "MonumentGroteskMedium";;
}

#open-modal-button {
    color: white;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 2px;
    text-decoration-color: rgba(255, 255, 255, 0.6);
    text-underline-offset: 3px;
}