:root {
  --color-bg: #0a0a0a;
  --color-fg-primary: #ffffff;
  --color-fg-secondary: #4d4d4d;
  --color-text-primary: #ffffff;
  --color-text-secondary: #707070;
  --body-font: "MonumentGrotesk", sans-serif;
  --body-font-italic: "MonumentGroteskItalic", sans-serif;
  --headline-font: "ReplicaLLWeb-Bold", sans-serif;
  --numeric-font: "ReplicaMonoLLWeb-Regular", sans-serif;
  --main-padding: 6%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 16px;
  color: var(--color-fg-primary);
  font-family: var(--body-font);
  -webkit-font-smoothing: anti;
}

// Need to add a reset class for all headers
h2 {
  font-weight: 100;
}

a {
  text-decoration: none;
  color: var(--color-text-primary);
}

a:hover {
  text-decoration: underline;
}

button {
  background: none;
  /* Removes background */
  color: inherit;
  /* Inherits the color from the parent element */
  border: none;
  /* Removes border */
  font: inherit;
  /* Inherits font properties from the parent */
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
  height: 100%; /* Optional: to ensure the page height doesn't change */
  width: 100%;
  // position: fixed;
}