#overlay {
    // visibility: hidden;
    // opacity: 0;
    // width: 100%;
    // height: 100%;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    
    
    // position: fixed;
  
    // transition: all 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    @media (max-width: 820px) {
      padding: 32px;
  }
  }
  
  /* Class to make elements visible */
  .overlay-visible {
    visibility: visible !important;
    opacity: 1 !important;
    backdrop-filter: blur(10px) !important;
  }

  