.logo {
    margin-bottom: 34px;
    position: relative;
    height: 24px;

    @media (max-width: 820px) {
        margin-bottom: 40px;
    }
}

.circle {
    background-color: var(--color-fg-secondary);
    border-radius: 13px;
    width: 24px;
    height: 24px;
    position: absolute;
    transition: all 1s ease-out;
}

.square-container {
    box-shadow: 0 0 0 5px var(--color-bg);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 18px;

}

.square {
    background-color: var(--color-fg-secondary);
    width: 100%;
    height: 100%;
    transition: all 1s ease-out;
    transform-origin: 0 0.5;
}


.outer-triangle {
    border: 20px solid #0000;
    border-top-width: 0;
    border-bottom: 40px solid #0a0a0a;
    align-items: center;
    width: 0;
    height: 0;
    display: flex;
    position: absolute;
    top: -10px;
    left: 24px;
    transform: rotate(0);
    transition: all 1s ease-out;
}

// .logo:hover .circle {
//     background-color: #FFB6B6;
// }

// .logo:hover .square {
//     transform: scaleX(1.3);
//     background-color: #F1B355;
// }

// .logo:hover .outer-triangle {
//     transform: rotate(90deg);
//     left: 49px;
//     top: -8px;
// }

// .logo:hover .outer-triangle .inner-triangle {
//     border-color: transparent transparent #FFF5D0 transparent;
// }

.inner-triangle {
    border-style: solid;
    border-width: 0 12px 24px;
    border-color: transparent transparent var(--color-fg-secondary) transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: 10.5px;
    left: -12px;
    transform: rotate(0);


}