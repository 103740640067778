:root {
  --color-bg: #0a0a0a;
  --color-fg-primary: #fff;
  --color-fg-secondary: #4d4d4d;
  --color-text-primary: #fff;
  --color-text-secondary: #707070;
  --body-font: "MonumentGrotesk", sans-serif;
  --body-font-italic: "MonumentGroteskItalic", sans-serif;
  --headline-font: "ReplicaLLWeb-Bold", sans-serif;
  --numeric-font: "ReplicaMonoLLWeb-Regular", sans-serif;
  --main-padding: 6%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
}

body {
  color: var(--color-fg-primary);
  font-size: 16px;
  font-family: var(--body-font);
  -webkit-font-smoothing: anti;
}

h2 {
  font-weight: 100;
}

a {
  color: var(--color-text-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: none;
}

.no-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: ReplicaLLWeb-Bold;
  src: url("ReplicaLLWeb-Bold.10584f36.woff") format("woff");
}

@font-face {
  font-family: ReplicaLLWeb-Bold;
  src: url("ReplicaLLWeb-Bold.2334b9ed.woff2") format("woff2");
}

@font-face {
  font-family: ReplicaMonoLLWeb-Regular;
  src: url("ReplicaMonoLLWeb-Regular.3a84cb92.woff") format("woff");
}

@font-face {
  font-family: ReplicaMonoLLWeb-Regular;
  src: url("ReplicaMonoLLWeb-Regular.de2927e0.woff2") format("woff2");
}

@font-face {
  font-family: MonumentGrotesk;
  src: url("ABCMonumentGrotesk-Regular.c8e0a5d7.woff") format("woff");
}

@font-face {
  font-family: MonumentGrotesk;
  src: url("ABCMonumentGrotesk-Regular.e5d75c8a.woff2") format("woff2");
}

@font-face {
  font-family: MonumentGrotesk-Italic;
  src: url("ABCMonumentGrotesk-RegularItalic.7cf8a4c5.woff") format("woff");
}

@font-face {
  font-family: MonumentGrotesk-Italic;
  src: url("ABCMonumentGrotesk-RegularItalic.166832d8.woff2") format("woff2");
}

@font-face {
  font-family: MonumentGroteskMedium;
  src: url("ABCMonumentGrotesk-Medium.e2688c57.woff") format("woff");
}

@font-face {
  font-family: MonumentGroteskMedium;
  src: url("ABCMonumentGrotesk-Medium.d504cdd1.woff2") format("woff2");
}

.wrapper {
  width: 50%;
  padding: 6% 3% 6% 6%;
  position: relative;
}

@media (width <= 820px) {
  .wrapper {
    width: 100%;
    padding: 48px 32px;
  }
}

.logo {
  height: 24px;
  margin-bottom: 34px;
  position: relative;
}

@media (width <= 820px) {
  .logo {
    margin-bottom: 40px;
  }
}

.circle {
  background-color: var(--color-fg-secondary);
  border-radius: 13px;
  width: 24px;
  height: 24px;
  transition: all 1s ease-out;
  position: absolute;
}

.square-container {
  box-shadow: 0 0 0 5px var(--color-bg);
  width: 24px;
  height: 24px;
  position: absolute;
  left: 18px;
}

.square {
  background-color: var(--color-fg-secondary);
  transform-origin: 0 .5px;
  width: 100%;
  height: 100%;
  transition: all 1s ease-out;
}

.outer-triangle {
  border: 20px solid #0000;
  border-top-width: 0;
  border-bottom: 40px solid #0a0a0a;
  align-items: center;
  width: 0;
  height: 0;
  transition: all 1s ease-out;
  display: flex;
  position: absolute;
  top: -10px;
  left: 24px;
  transform: rotate(0);
}

.inner-triangle {
  border-style: solid;
  border-width: 0 12px 24px;
  border-color: transparent transparent var(--color-fg-secondary) transparent;
  width: 0;
  height: 0;
  position: absolute;
  top: 10.5px;
  left: -12px;
  transform: rotate(0);
}

@property --gradient-angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: -15deg;
}

@keyframes rotation {
  0% {
    --gradient-angle: -15deg;
  }

  100% {
    --gradient-angle: 345deg;
  }
}

.work-pill {
  color: var(--color-text-primary);
  cursor: pointer;
  border-radius: 8px;
  margin: 0 8px 0 5px;
  display: inline-block;
  position: relative;
}

.work-pill:before, .work-pill:after {
  content: "";
  border-radius: inherit;
  background: conic-gradient(from var(--gradient-angle), #292929, #454545, #9a9a9a, #454545, #292929);
  opacity: .4;
  height: 24px;
  transition: opacity .5s;
  animation: 15s linear infinite rotation;
  position: absolute;
  inset: 2px -5px 0 -1px;
}

@media (width <= 820px) {
  .work-pill:before, .work-pill:after {
    height: 30px;
    font-size: 19px;
    inset: 2px -5px 0 -1px;
  }
}

.work-pill:before {
  filter: blur(4px);
  transform: translate3d(0, 0, 0);
}

.safari-desktop.work-pill:before {
  filter: none;
  transform: translate3d(0, 0, 0);
}

.work-pill:hover:after, .work-pill:hover:before {
  opacity: .8;
}

.inset-span {
  overflow: visible;
}

.inset-span:after {
  content: "";
  z-index: 1;
  backdrop-filter: blur(10px);
  background: #1515156b;
  border-radius: 7px;
  height: 22px;
  position: absolute;
  top: 3px;
  left: 0;
  right: -4px;
}

@media (width <= 820px) {
  .inset-span:after {
    height: 28px;
    top: 3px;
  }
}

.work-pill-text {
  z-index: 2;
  margin-right: 1px;
  padding-left: 6px;
  font-family: MonumentGroteskMedium;
  font-size: 15px;
  position: relative;
}

@media (width <= 820px) {
  .work-pill-text {
    font-size: 19px;
  }
}

.work-pill-icon {
  z-index: 2;
  margin-right: 1px;
  position: relative;
  transform: translateY(.5px);
}

@media (width <= 820px) {
  .work-pill-icon {
    transform: translateY(-.5px);
  }
}

#modal {
  opacity: 0;
  z-index: 4;
  background: #101010;
  border: 1px solid #ffffff0d;
  border-radius: 50px;
  width: 400px;
  margin-top: 200px;
  padding: 48px;
  box-shadow: 0 15px 50px #00000080;
}

@media (width <= 820px) {
  #modal {
    padding: 32px;
  }
}

#close-modal-button {
  float: right;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 32px;
  right: 32px;
}

.modal-header {
  font-size: 28px;
  font-family: var(--headline-font);
  color: var(--color-text-primary);
  margin-bottom: 10px;
}

.modal-paragraph {
  color: var(--color-text-secondary);
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 160%;
}

.modal-updated {
  margin-bottom: 0;
  font-family: MonumentGrotesk-Italic;
}

.modal-link {
  color: #fff;
  font-family: MonumentGroteskMedium;
}

#open-modal-button {
  color: #fff;
  text-underline-offset: 3px;
  text-decoration: underline 2px dotted #fff9;
}

.section-intro {
  margin-bottom: 30px;
}

@media (width <= 820px) {
  .section-intro {
    margin-bottom: 36px;
  }
}

.section-work, .section-side-projects, .section-writing, .section-patents {
  margin-bottom: 32px;
}

@media (width <= 820px) {
  .section-work, .section-side-projects, .section-writing, .section-patents {
    margin-bottom: 40px;
  }
}

.section-elsewhere {
  margin-bottom: 32px;
}

@media (width <= 820px) {
  .section-elsewhere {
    margin-bottom: 38px;
  }
}

.section-header {
  color: var(--color-text-secondary);
  text-transform: uppercase;
  margin-bottom: .6em;
  font-family: ReplicaMonoLLWeb-Regular;
  font-size: 12px;
}

.section-intro-header {
  font-size: 2.25em;
  font-family: var(--headline-font);
  color: var(--color-text-secondary);
  margin-bottom: 22px;
  font-weight: 400;
}

@media (width <= 820px) {
  .section-intro-header {
    margin-bottom: 28px;
    font-size: 38px;
  }
}

.section-intro-header-callout {
  color: var(--color-text-primary);
}

.section-intro p {
  color: var(--color-text-secondary);
  margin-bottom: 22px;
  font-size: 1.125em;
  line-height: 156%;
}

.section-intro p:last-of-type {
  margin-bottom: 0;
}

@media (width <= 820px) {
  .section-intro p {
    margin-bottom: 30px;
    font-size: 22px;
  }
}

#spline-player-layout-container {
  padding: var(--main-padding) var(--main-padding) var(--main-padding) 3%;
  z-index: 3;
  text-align: center;
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

@media (width <= 820px) {
  #spline-player-layout-container {
    pointer-events: auto;
    width: 100%;
    padding: 0;
    display: none;
    inset: 0;
  }
}

.spline-player-layout-container-visible {
  pointer-events: auto;
  display: block !important;
}

.spline-player-clipping-container {
  border: 1px solid #ffffff0f;
  border-radius: 80px;
  width: 100%;
  height: 96%;
  overflow: hidden;
}

@media (width <= 820px) {
  .spline-player-clipping-container {
    pointer-events: none;
    border: none;
    border-radius: 0;
    height: 100%;
  }
}

.spline-player-label-layout-container {
  justify-content: center;
  align-items: end;
  height: 4%;
  display: flex;
}

@media (width <= 820px) {
  .spline-player-label-layout-container {
    align-items: center;
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;
  }
}

.spline-player-label {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: ReplicaMonoLLWeb-Regular;
  font-size: 12px;
  display: inline-flex;
}

.spline-player-label div {
  background-color: #fff3;
  border-radius: 2px;
  width: 4px;
  height: 4px;
}

#icon-close-spline-viewer {
  display: none;
  top: 32px;
  right: 32px;
}

@media (width <= 820px) {
  #icon-close-spline-viewer {
    display: block;
  }
}

footer {
  color: var(--color-text-secondary);
  justify-content: space-between;
  font-size: .75em;
  display: flex;
}

.footer-quote, .modal-updated {
  font-family: MonumentGrotesk-Italic;
}

.footer-details-button {
  cursor: pointer;
}

.icon-close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
}

.icon-close div {
  background-color: #fff;
  width: 2.5px;
  height: 20px;
  position: absolute;
}

.icon-close div:first-of-type {
  top: 2px;
  left: 11px;
  transform: rotate(-45deg);
}

.icon-close div:last-of-type {
  top: 2px;
  left: 11px;
  transform: rotate(45deg);
}

#overlay {
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width <= 820px) {
  #overlay {
    padding: 32px;
  }
}

.overlay-visible {
  visibility: visible !important;
  opacity: 1 !important;
  backdrop-filter: blur(10px) !important;
}

.section-item {
  margin-bottom: .5em;
  display: flex;
}

.section-item-left {
  font-size: 1.125em;
  font-family: var(--headline-font);
  flex: 1;
  display: flex;
}

@media (width <= 820px) {
  .section-item-left {
    font-size: 22px;
  }
}

.section-item-left-plain {
  font-size: 18px;
  font-family: var(--headline-font);
}

@media (width <= 820px) {
  .section-item-left-plain {
    font-size: 22px;
  }
}

.section-item-left:after {
  content: "";
  border-bottom: 2px dotted #ffffff1a;
  flex: 1;
  margin: 0 8px 3px;
}

.section-item-right {
  font-family: var(--numeric-font);
  text-transform: uppercase;
  color: var(--color-text-secondary);
  align-self: flex-end;
  font-size: .875em;
}

.section-elsewhere ol {
  list-style: none;
}

.section-item-right-patents {
  font-family: var(--numeric-font);
  text-transform: uppercase;
  text-align: end;
  font-size: 10px;
}

.section-item-right-patents span:first-of-type {
  color: var(--color-text-primary);
  display: block;
}

.section-item-right-patents span:last-of-type {
  color: var(--color-text-secondary);
  display: block;
}
/*# sourceMappingURL=index.48533f4c.css.map */
