.section-intro {
    margin-bottom: 30px;

    @media (max-width: 820px) {
        
        margin-bottom: 36px;

    }
}

.section-work,
.section-side-projects,
.section-writing,
.section-patents {
    margin-bottom: 32px;

    @media (max-width: 820px) {
        margin-bottom: 40px;
    }
}

.section-elsewhere {
    margin-bottom: 32px;

    @media (max-width: 820px) {   
        margin-bottom: 38px;
    }
}

.section-header {
    font-family: 'ReplicaMonoLLWeb-Regular';
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: .6em;
}

.section-intro-header {
    font-size: 2.25em;
    font-family: var(--headline-font);
    margin-bottom: 22px;
    color: var(--color-text-secondary);
    // This fixes font rendering in Safari
    font-weight: 400;

    @media (max-width: 820px) {
        font-size: 38px;
        margin-bottom: 28px;
    }
}

.section-intro-header-callout {
    color: var(--color-text-primary);
}

.section-intro p {
    font-size: 1.125em;
    margin-bottom: 22px;
    color: var(--color-text-secondary);
    line-height: 156%;

    &:last-of-type {
        margin-bottom: 0px;
    }

    @media (max-width: 820px) {
        font-size: 22px;
        margin-bottom: 30px;

    }

    
}



